import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import Lightbox from "./Lightbox";


const ScreenshotGallery = () => {
    const data = useStaticQuery(graphql`
        query MyQuery {
            allFile(
                filter: {sourceInstanceName: {eq: "screenshots"}}
                sort: {fields: childrenMdx___frontmatter___date}
                ) {
                nodes {
                    childMdx {
                        frontmatter {
                            image {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                        }
                    }
                }
            }
        }
        
    `)

    const images = data.allFile.nodes.map(node => node.childMdx.frontmatter.image)

    return (
        <Lightbox images={images}/>
    )
};

export default ScreenshotGallery;