// extracted by mini-css-extract-plugin
export var attr = "index-module--attr--zwaMl";
export var button = "index-module--button--11jy-";
export var column = "index-module--column--mfLU0";
export var discord = "index-module--discord--pFx+F";
export var downloadbuttons = "index-module--downloadbuttons---Q0aK";
export var downloadimg = "index-module--downloadimg--8t9hF";
export var halfhalf = "index-module--halfhalf--Ax1o+";
export var headerresponsive = "index-module--headerresponsive--+XsI7";
export var hero = "index-module--hero--RXViO";
export var herocontent = "index-module--herocontent--FcKYK";
export var hidden = "index-module--hidden--fy8Ri";
export var legal = "index-module--legal--twWVD";
export var pagedivider = "index-module--pagedivider--+yyLl";
export var wholepage = "index-module--wholepage--NFyC3";
export var youtube = "index-module--youtube--lj7VO";