// extracted by mini-css-extract-plugin
export var active = "NewsList-module--active--kUMei";
export var closebutton = "NewsList-module--closebutton--S72vN";
export var closex = "NewsList-module--closex--O082H";
export var fadein = "NewsList-module--fadein--s2ZZi";
export var header = "NewsList-module--header--RD6Dc";
export var image = "NewsList-module--image--jsAU+";
export var mdbody = "NewsList-module--mdbody--SCWt5";
export var news = "NewsList-module--news--wzNZ3";
export var newsheading = "NewsList-module--newsheading--VE5Th";
export var newsimage = "NewsList-module--newsimage--Xsy3c";
export var newsitem = "NewsList-module--newsitem--OOKve";
export var newspopup = "NewsList-module--newspopup--eESUt";
export var newsread = "NewsList-module--newsread--z9P7K";
export var newsreadcontainer = "NewsList-module--newsreadcontainer--2fdak";
export var newstext = "NewsList-module--newstext--W5lbb";
export var popupinner = "NewsList-module--popupinner--Slrhs";
export var readmore = "NewsList-module--readmore--Aeq-9";
export var readmore_wrapper = "NewsList-module--readmore_wrapper--QoWox";
export var title = "NewsList-module--title--lNb-n";
export var titlewrapper = "NewsList-module--titlewrapper--0k2r1";