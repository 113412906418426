import * as React from "react"
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image"
import "modern-css-reset"
import "./global.css"
import * as IndexStyles from './index.module.css'

import ScreenshotGallery from '../components/ScreenshotGallery';
import NewsList from "../components/NewsListWrapper";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      hamburger: "list",
    }
  }
  render() {
    return (
      <header>
      <div className={IndexStyles.headerresponsive}>
        <h4>Sky On Fire: 1940</h4>
        <i id="hamburger" className={`bi bi-${this.state.hamburger}`} role="button" onClick={() => {
          this.setState({ hidden: !this.state.hidden });
          if (this.state.hamburger === "list") {
            this.setState({ hamburger: "x" });
          } else if (this.state.hamburger === "x") {
            this.setState({ hamburger: "list" });
          }
        }}></i>
      </div>
      <nav className={this.state.hidden ? IndexStyles.hidden : ""}>
        <a href="#about">ABOUT</a>
        <a href="#news">NEWS</a>
        <a href="">WIKI</a>
        <a href="#play" className={IndexStyles.button} onClick={() => {
          if (/Android/i.test(navigator.userAgent)) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.ISNIindustries.SkyOnFire&hl=en_GB&gl=US";
          } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            window.location.href = "https://apps.apple.com/us/app/sky-on-fire-1940/id1617460156";
          } else if (/Windows/i.test(navigator.userAgent)) {
            window.location.href = "https://isni.itch.io/sky-on-fire-1940";
          }}
        }>PLAY</a>
      </nav>
    </header>
    );
  } 
}

const IndexPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Sky on Fire: 1940</title>
      </Helmet>
      <div className={IndexStyles.wholepage}>
      <div className={IndexStyles.hero}>
        <Header />
        <div className={IndexStyles.herocontent}>
          <h1 id="play">SKY ON FIRE: 1940</h1>
          <div className={IndexStyles.downloadbuttons}>
            <a href="https://apps.apple.com/us/app/sky-on-fire-1940/id1617460156">
              <StaticImage src="../images/downloads/appstore.png" imgClassName={IndexStyles.downloadimg} height={40}/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.ISNIindustries.SkyOnFire&hl=en_GB&gl=US">
                <StaticImage src="../images/downloads/google.png" imgClassName={IndexStyles.downloadimg} height={40}/>
            </a>
            <a href="https://isni.itch.io/sky-on-fire-1940">
              <StaticImage src="../images/downloads/itch.png" imgClassName={IndexStyles.downloadimg} height={40}/>
            </a>
          </div>
        </div>
      </div>
      <div className={IndexStyles.pagedivider}></div>
      <div className={IndexStyles.column}>
        <h2 id="about">FLY DURING THE EARLIEST STAGES OF WW2</h2>
        <p>Pilot a variety of aircraft from the the Battle of Britain and Battle of France. Interact with your plane in a multitude of ways, and control any crewmember in your plane in first person. Simple graphics, but with attention to detail regarding the aircraft, unique sounds for each engine type, and guns.</p>
        <div className={IndexStyles.halfhalf}>
          <div>
            <ul>
              <li>Realistic damage and flight models</li>
              <li>Play as pilot, bombardier or gunner</li>
              <li>Sandbox features and mission editor</li>
            </ul>
          </div>
          <div>
            <a href="#" id={IndexStyles.youtube}><i class="bi bi-youtube"></i>&nbsp;YouTube</a>
            <a href="https://discord.gg/K3Uz5zzYhK" id={IndexStyles.discord}><i class="bi bi-discord"></i>&nbsp;Discord</a>
          </div>
        </div>
        <ScreenshotGallery/>
        <NewsList/>
      
      </div>
      <footer>
        <p className={IndexStyles.attr}>Made with ❤️ by <a href="https://github.com/wsandy1">Will S</a></p>
        <p className={IndexStyles.legal}>Google Play and the Google Play logo are trademarks of Google LLC. Apple logo® and App Store® are trademarks of Apple Inc.</p>
      </footer>
      </div>
    </React.Fragment>
  )
}

export default IndexPage
