import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby'


import NewsList from "./NewsList"

const NewsListWrapper = () => {
    const data = useStaticQuery(graphql`
        query {
            allFile(
                filter: {sourceInstanceName: {eq: "news"}}
                sort: {fields: childrenMdx___frontmatter___date, order: DESC}
            ) {
                nodes {
                    childMdx {
                        frontmatter {
                            date(formatString: "M/D/YYYY")
                            title
                            type
                            thumbnail {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                        }
                        id
                        body
                    }
                }
            }
        }
    `)

    return (
        <NewsList images={data}/>
    )
}

export default NewsListWrapper