import * as React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { MDXRenderer } from 'gatsby-plugin-mdx'


import '../pages/global.css';
import * as styles from "./NewsList.module.css"

class NewsList extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showNews: false,
            selectedNews: null,
            limit: 4,
            count: this.props.images.allFile.nodes.length,
            type: 'all',
        }
    }

    render() {
        return (
            <React.Fragment>
                <div class={styles.newsheading} id="news">
                    <h2>LATEST</h2>
                    <span onClick={() => {
                        this.setState({ type: 'all', count: this.props.images.allFile.nodes.length});
                    }} className={this.state.type === "all" ? styles.active : ""} onKeyDown={() => {
                        this.setState({ type: 'all', count: this.props.images.allFile.nodes.length});
                    }} role="button">All</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span onClick={() => {
                        this.setState({ type: 'patch', count: this.props.images.allFile.nodes.filter(mdx => mdx.childMdx.frontmatter.type === "patch").length});
                    }} className={this.state.type === "patch" ? styles.active : ""} onKeyDown={() => {
                        this.setState({ type: 'patch', count: this.props.images.allFile.nodes.filter(mdx => mdx.childMdx.frontmatter.type === "patch").length});
                    }} role="button">Patch Notes</span>
                </div>
                <div class={styles.news}>
                {      
                    this.props.images.allFile.nodes.slice(0, this.state.limit).map(mdx => {
                        if (this.state.type === 'all') {
                            return(
                                <div className={styles.newsitem} key={mdx.childMdx.id} onClick={() => this.setState({ showNews: true, selectedNews: mdx, })} onKeyDown={() => this.setState({ showNews: true, selectedNews: mdx, })} role="button">
                                    <GatsbyImage className={styles.newsimage} image={getImage(mdx.childMdx.frontmatter.thumbnail)}/>
                                    <div class={styles.newstext}>
                                        <h4>{mdx.childMdx.frontmatter.title}</h4>
                                        <span>{mdx.childMdx.frontmatter.date}</span>
                                    </div>
                                    <div className={styles.newsreadcontainer}>
                                        <span className={styles.newsread}>READ</span>
                                    </div>
                                </div>
                            )
                        } else if (this.state.type === 'patch' && mdx.childMdx.frontmatter.type === 'patch') {
                            return(
                                <div className={styles.newsitem} key={mdx.childMdx.id} onClick={() => this.setState({ showNews: true, selectedNews: mdx, })} onKeyDown={() => this.setState({ showNews: true, selectedNews: mdx, })} role="button">
                                    <GatsbyImage className={styles.newsimage} image={getImage(mdx.childMdx.frontmatter.thumbnail)}/>
                                    <div class={styles.newstext}>
                                        <h4>{mdx.childMdx.frontmatter.title}</h4>
                                        <p>{mdx.childMdx.frontmatter.date}</p>
                                    </div>
                                    <div className={styles.newsreadcontainer}>
                                        <span className={styles.newsread}>READ</span>
                                    </div>
                                </div>
                            )
                        } else {
                            return null; 
                        }
                    })
                }
                </div>

                {this.state.count > this.state.limit &&
                    <div className={styles.readmore_wrapper}><span className={styles.readmore} onClick={() => this.setState({ limit: this.state.limit + 3 })} onKeyDown={() => this.setState({ limit: this.state.limit + 3 })} role="button">READ MORE</span></div>
                }

                {this.state.showNews && (
                <div className={styles.newspopup}>
                    <div className={styles.popupinner}>
                        <div className={styles.header}>
                            <div className={styles.titlewrapper}>
                                <h1 className={styles.title}>{this.state.selectedNews.childMdx.frontmatter.title}</h1>
                                <span>{this.state.selectedNews.childMdx.frontmatter.date}</span>
                            </div>
                            <span className={styles.closex} onClick={() => this.setState({showNews: false, selectedNews: null})} onKeyDown={() => this.setState({showNews: false, selectedNews: null})} role="button">𐌢</span>
                        </div>
                        <GatsbyImage className={styles.image} image={getImage(this.state.selectedNews.childMdx.frontmatter.thumbnail)} imgStyle={{ objectPosition: '50% 50%' }}/>
                        <div className={styles.mdbody}>
                            <MDXRenderer>
                                {this.state.selectedNews.childMdx.body}
                            </MDXRenderer>
                        </div>
                        <span className={styles.closebutton} onClick={() => this.setState({showNews: false, selectedNews: null})} onKeyDown={() => this.setState({showNews: false, selectedNews: null})} role="button">CLOSE</span>
                    </div>
                </div>)}

            </React.Fragment>
        )
    }
}

export default NewsList