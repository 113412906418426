import * as React from 'react';
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import * as LightboxStyles from "./Lightbox.module.css"


class Lightbox extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showLightbox: false,
            selectedImage: null,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={LightboxStyles.gallery}>
                    {
                        this.props.images.map(image => (
                            <span onKeyDown={() => this.setState({showLightbox: true, selectedImage: image})} onClick={() => this.setState({showLightbox: true, selectedImage: image})} role="button" key={image.id}>
                                <GatsbyImage image={getImage(image)} className={LightboxStyles.screenshot}/>
                            </span>
                        ))
                    }
                </div>
                {this.state.showLightbox && (
                        <div className={LightboxStyles.lightbox} onClick={() => this.setState({showLightbox: false, selectedImage: null})} onKeyDown={() => this.setState({showLightbox: false, selectedImage: null})} role="button">
                            <GatsbyImage image={getImage(this.state.selectedImage)} className={LightboxStyles.lightboximage} imgStyle={{ objectFit: 'contain' }}/>
                        </div>
                    )
                }

            </React.Fragment>
        )
    }
};

export default Lightbox;